<template>
  <div>
    <div class="right">
      <div class="right-content" v-for="item of list" :key="item.id">
        <div class="right-content-img">
          <img :src="imgURL+item[0].image" width="100%" height="100%" alt="">
        </div>
        <div class="right-content-text">
          <div class="right-content-text-title">{{item[0].title}}</div>
          <div class="right-content-text-value">{{item[0].details}}</div>
          <!-- <div class="right-content-text-btn">{{item[0].isprice===0?'免费':item[0].price}}</div> -->
        </div>
        <div class="right-content-jia el-icon-plus"></div>
        <div class="right-content-img">
          <img :src="imgURL+item[1].image" width="100%" height="100%" alt="">
        </div>
        <div class="right-content-text">
          <div class="right-content-text-title">{{item[1].title}}</div>
          <div class="right-content-text-value">{{item[1].details}}</div>
          <!-- <div class="right-content-text-btn">{{item[1].isprice===0?'免费':item[1].price}}</div> -->
        </div>
        <div class="right-content-btn" @click="go" v-if="tType == 'CH'">在线预约</div>
        <div class="right-content-btn" @click="go" v-if="tType == 'EN'">online appointment</div>
      </div>
    </div>
    <div class="paging-device">
      <el-pagination
        layout="prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current_page"
        :page-size="per_page"
        :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getticketing, imgURL} from '@/api'
export default {
  name: "combination",
  data(){
    return {
      imgURL,
      tType:'CH',
      list:[],
      totalCount: 0,
      current_page: 1,
      per_page: 4,
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    // this.createlist()
  },
  methods:{
    // 每页条数
        handleSizeChange(val) {
        this.per_page=val
        this.createlist()
      },
      // 页数
      handleCurrentChange(val) {
        this.current_page=val
        this.createlist()
      },
    createlist(){
      getticketing({
        type:1
      })
          .then(res=>{
            console.log(res);
            if(res.code==200){
              this.list=res.rows
              this.totalCount=res.total
            }
          })
    },
    go(){
      // this.$router.push('/playturn/playtyrndetails')
    }
  }
}
</script>

<style scoped lang="less">
.right{
  width: 1628px;
  height: 800px;
  display: inline-block;
  margin-right: 117px;
  //overflow: scroll;
  ////background: skyblue;
  //&::-webkit-scrollbar {
  //  width: 0px;
  //}
  .right-content{
    width: 1628px;
    height: 264px;
    background: #F5F5F5;
    margin-left: -55px;
    margin-bottom: 30px;
    padding: 30px 0;
    padding-left: 83px;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    font-family: 'JDZY';
    .right-content-img{
      width: 300px;
      height: 200px;
      margin-right: 30px;
    }
    .right-content-text{
      width: 220px;
      height: 200px;
      .right-content-text-title{
        width: 100%;
        height: 33px;
        overflow: hidden;
        font-weight: 700;
        font-size: 24px;
        margin-top: 15px;
        margin-bottom: 18px;
      }
      .right-content-text-value{
        width: 100%;
        height: 75px;
        overflow: hidden;
        font-size: 14px;
        color: #999999;
        margin-bottom: 24px;
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /*截取第三行*/
        overflow: hidden;
      }
      .right-content-text-btn{
        color: #37808F;
        font-size: 28px;
        font-weight: 700;
      }
    }
    .right-content-jia{
      width: 150px;
      height: 200px;
      color: #231916;
      font-weight: 700;
      font-size: 50px;
      color: #337D8D;
      text-align: center;
      line-height: 200px;
    }
    .right-content-btn{
      width: 147px;
      height: 56px;
      background: #337D8D;
      color: #FFFFFF;
      text-align: center;
      line-height: 56px;
      margin-top: 145px;
      border-radius: 5px;
      font-size: 20px;
    }
  }
}
/deep/.paging-device {
  margin: 70px 0 70px -50px;
  display: flex;
  justify-content: center;
}
</style>
